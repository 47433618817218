body {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  *::selection {
    background-color: rgba(121, 255, 255, 0.5);
  }
}

body.dark {
  *::selection {
    background-color: rgba(238, 10, 218, 0.5);
  }
}

a,
button,
input,
textarea,
select,
video {
  @apply outline-none;
  &:focus {
    @apply outline-none;
  }
  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }
}

.bg-lighter {
  background-color: rgba(150, 150, 150, 0.2);
}

.phone-number-input {
  input {
    background-color: transparent !important;
    @apply placeholder-light-200 dark:placeholder-dark-A200 text-dark-100 dark:text-light-100;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #f7f7f7 inset;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: rgba(155, 155, 155, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.4);
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-primary-50 bg-opacity-50;
}

.ReactModal__Overlay {
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.ReactModal__Content {
  @media (min-width: 1023px) {
    max-width: 50vw !important;
  }
}

.container-fluid {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}
